import React from "react";
import ComingSoon from "../ComingSoon/ComingSoon";
import { useEffect, useRef } from "react";


// Mui Card
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// Chart js
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';

import { Bar, Line} from 'react-chartjs-2';
import { DashboardApi } from "../../api/DashboardApi";


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
  );

// CounterBOX
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
	...theme.typography.body2,
	padding: "20px",
	boxShadow: "none",
	borderRadius: "10px",
	border: "1px solid #E7E7E7",
	color: "black",
	height: "100%",
}));

function CounterBox(props) {
	return (
		<Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
			<Item className="counter-box">
				<div className="card-title">{props.title}</div>
				
				<div className="d-flex">
					<span>{props.count}</span>

					{props.imageUrl &&
						<div className="img-container">
							<img src={props.imageUrl} alt="Product" />
						</div>
					}
					<div style={{ fontSize: '20px' }}>{props.activeplan}</div>

				</div>
				
				

				{props.end_date ? (
					<div className="date-container">
						<div className="end-date date">
							<span>
								Expiry Date
							</span>
							{props.end_date}
						</div>
						
					</div>) : (<>
							
					</>)}
			</Item>
		</Grid>
	);
}

// Most Popular
function MostPopular(props) {

	

	return (
		<>
			<Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
				<Item className="popular-box">
					<div className="card-title-container">
						<div className="card-title">{props.cardtitle}</div>
						{/* <button className='green button' >
                            View Utilisation Report
                        </button> */}
					</div>
					<div className="popular-product">
						<div className="left">
							<div className="img-container">
								<img src={'https://api.growinggiraffe.com/' + props.product_img} alt="" />
							</div>
							{props.name}
						</div>
						<div className="right">
							<div className="heading">Product Stats</div>
							<div className="block">
								<span>{props.days} </span>
								<br />
								Wishlist
							</div>
							<div className="block">
								<span>₹{props.revenue} </span>
								<br />
								Revenue Generated last month
							</div>
							<div className="block">
								<span>{props.inventory}</span>
								<br />
								Item Inventory
							</div>
						</div>
					</div>
				</Item>
			</Grid>
		</>
	);
}

function OrderChart(props){
	useEffect(() => {
		(async () => {
		  try {
			const data = await DashboardApi();
			console.log(data);
		  } catch (error) {
			console.log(error);
		  }
		})();
	  }, []);
	  console.log(props.data);
	  const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
                font: {
                  family: 'Sora, sans-serif',
                  size: 8
                },
                boxWidth: 22, // Adjust the boxWidth to control the width of the color box next to the legend text
                padding: 10, // You can adjust the padding to add space between the legend items
              }
          },   
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: 'Sora, sans-serif',
                size: 8
              }
            },
            grid: {
              display: false // Disables vertical grid lines
            }
          },
          y: {
            ticks: {
              font: {
                family: 'Sora, sans-serif',
                size: 8
              },
              // Set custom y-axis scale values
              callback: function(value) {
                return value;
              },
              min: -20,
              max: 100,
              stepSize: 20,
              
            }
          }
        }
      };
      
      const labels = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O','N','D'];
      
      const data = {
        labels,
        datasets: [
          {
            label: '2022',
            data: [-15, 30,-10, 43, 47, -20],
            backgroundColor: '#8B4BFA',
            borderColor: '#8B4BFA',
            borderWidth: 2, // Set the width of the line
            
          },
          
        ]
      };

	return (
		<>
			<Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
				<Item className="popular-box">
					<Line options={options} data={data} />
				</Item>
			</Grid>
		</>
	)
}

function HotCategories(props){
	return (
		<>
		</>
	)
}



export { CounterBox, MostPopular , HotCategories, OrderChart};
