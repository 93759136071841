import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';


// Components
import { CounterBox } from '../../admin/components/DashboardComponents/DashboardComponents';
import { CustomerWishlistTable } from '../../admin/components/Table/TableComponent';
import '../../admin/style/components/DashboardComponents/DashboardComponents.css'

// API
import { CustomerInfoApi } from '../api/CustomerInfoApi';
import { SubscriptionInfoApi } from '../api/SubscriptionInfo';
import { CustomerWishlistApi } from '../api/CustomerWishlist';
import { NavLink } from 'react-router-dom';

function CustomerDashboard() {

	const [subscriptionInfo, setSubscriptionInfo] = useState()
	const [customerInfo, setCustomerInfo] = useState()
	const [CustomerWishlist, setCustomerWishList] = useState()
	const [nextUrl, setNextUrl] = useState(null);
	const [prevUrl, setPrevUrl] = useState(null);
	const [pages, setPages] = useState([])
	useEffect(() => {
		const fetchCustomer = async () => {
			try {
				const data = await CustomerInfoApi();
				console.log(data)
				setCustomerInfo(data.gg_token + data.topup_token); // Make sure you also set the fetched data
			} catch (error) {
				setCustomerInfo(0)
			}
		}
		const fetchSubscription = async () => {
			try {
				const data = await SubscriptionInfoApi();
				console.log(data)

				const result = data.results.map(item => ({
					id: item.subscription.id,
					order_by: item.subscription.order_by,
					created_at: item.subscription.created_at,
					updated_at: item.subscription.updated_at,
					image: item.subscription.image,
					name: item.subscription.name,
					description: item.subscription.description,
					price: item.subscription.price,
					total_gg_token: item.subscription.total_gg_token,
					exchange_month: item.subscription.exchange_month,
					max_point_per_cycle: item.subscription.max_point_per_cycle,
					refundable_deposit: item.subscription.refundable_deposit,
					total_month: item.subscription.total_month,
					is_active: item.subscription.is_active,
					start_date: item.subscription_from, // This should be corrected based on your API response
					end_date: item.subscription_to, // This should be corrected based on your API response
				}));

				setSubscriptionInfo(result[0]); // Make sure you also set the fetched data
			} catch (error) {
				setSubscriptionInfo(0);
			}
		};
		const fetchWishList = async () => {
			try {
				const data = await CustomerWishlistApi();
				let next;
				let prev;
				console.log(data)
				setCustomerWishList(data.data.results)
				try {
					next = data.data.links.next.replace("http:", "https:");
				} catch {
					next = data.data.links.next;
				}
				try {
					prev = data.data.links.previous.replace("http:", "https:");
				} catch {
					prev = data.data.links.previous;
				}
				// Assuming `data` is your response object with necessary pagination details
				let currentPage = data.data.current_page;
				let pages = data.data.links.pages;
				let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
				let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
				let paginationLinks = [];

				// Add the previous page link if it's not the first page
				// if (currentPage > 1) {
				// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
				// }

				// Add up to three previous page links
				for (let i = currentPage - 2; i < currentPage; i++) {
					if (i > 0) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the current page link
				paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

				// Add up to three next page links
				for (let i = currentPage + 1; i <= currentPage + 3; i++) {
					if (i <= pages.length) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the next page link if it's not the last page
				// if (currentPage < pages.length) {
				// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
				// }

				console.log(paginationLinks);

				// Now set the state or render the pagination
				setPages(paginationLinks);
				setNextUrl(nextPageUrl);
				setPrevUrl(prevPageUrl);
			} catch (error) {

			}
		}
		fetchCustomer();
		fetchSubscription();
		fetchWishList();
		
	}, []);

	const handleNext = async () => {
		try {
			const response = await axios.get(nextUrl, { withCredentials: true });
			const data = response.data;
			console.log("", data)
			setCustomerWishList(data.results);
			let currentPage = data.current_page;
			let pages = data.links.pages;
			let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
			let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
			let paginationLinks = [];

			// Add the previous page link if it's not the first page

			// Add up to three next page links
			for (let i = currentPage + 1; i <= currentPage + 4; i++) {
				if (i <= pages.length) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the next page link if it's not the last page
			// if (currentPage < pages.length) {
			// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
			// }

			// Now set the state or render the pagination
			setPages(paginationLinks);
			setNextUrl(nextPageUrl);
			setPrevUrl(prevPageUrl);

		} catch {
			console.log('here is an error')
		}
	}
	const handlePrev = async () => {
		try {
			const response = await axios.get(prevUrl, { withCredentials: true });
			const data = response.data;
			let prev;
			let next;
			setCustomerWishList(data.results);
			let currentPage = data.current_page;
			let pages = data.links.pages;
			let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
			let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
			let paginationLinks = [];

			// // Add the previous page link if it's not the first page
			// if (currentPage > 1) {
			// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
			// }

			// Add up to three previous page links
			for (let i = currentPage - 2; i < currentPage; i++) {
				if (i > 0) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the current page link
			paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

			// Add up to three next page links
			for (let i = currentPage + 1; i <= currentPage + 3; i++) {
				if (i <= pages.length) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the next page link if it's not the last page
			// if (currentPage < pages.length) {
			// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
			// }

			// Now set the state or render the pagination
			setPages(paginationLinks);
			setNextUrl(nextPageUrl);
			setPrevUrl(prevPageUrl);

		} catch {

		}
	}
	const handlePage = async (e) => {
		try {
			const response = await axios.get(e, { withCredentials: true });
			const data = response.data;
			setCustomerWishList(data.results);
			let currentPage = data.current_page;
			let pages = data.links.pages;
			let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
			let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
			let paginationLinks = [];

			// Add the previous page link if it's not the first page
			// if (currentPage > 1) {
			// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
			// }

			// Add up to three previous page links
			for (let i = currentPage - 2; i < currentPage; i++) {
				if (i > 0) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the current page link
			paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

			// Add up to three next page links
			for (let i = currentPage + 1; i <= currentPage + 3; i++) {
				if (i <= pages.length) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the next page link if it's not the last page
			// if (currentPage < pages.length) {
			// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
			// }

			console.log(paginationLinks);

			// Now set the state or render the pagination
			setPages(paginationLinks);
			setNextUrl(nextPageUrl);
			setPrevUrl(prevPageUrl);


		} catch (error) {
			console.error("Error fetching the previous page:", error);
		}
	}

	return (
		<>
			<div className="title-container" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
				<h5>Dashboard</h5>
				{/* <div className='rent-now'>Renew subscription</div> */}
				{!subscriptionInfo ? (
					<NavLink to={'/pricing'}>
						<button className='button green'>Renew Subscription</button>
					</NavLink>
				) : ''}
			</div>

			<Box sx={{ flexGrow: 1, mt: 2 }}>
				<Grid container spacing={2}>
					<CounterBox extrasmall={12} small={6} imageUrl={'images/ggtoken.svg'} medium={4} title={'My Wallet'} count={customerInfo}></CounterBox>
					{subscriptionInfo ? (<>
						<CounterBox extrasmall={12} small={6} imageUrl={'images/monkey.png'} medium={4} title={'Active Plan'} start_date={subscriptionInfo.start_date} end_date={subscriptionInfo.end_date} activeplan={subscriptionInfo.name}></CounterBox>
					</>) : (<>
						<CounterBox extrasmall={12} small={6} renewBtn={true} imageUrl={'images/no-active-plan.png'} medium={4} title={'No Active Plan'}></CounterBox>
					</>)}
					<CounterBox extrasmall={12} small={6} imageUrl={'images/coming-soon.png'} medium={4} title={'Child Stats'} ></CounterBox>
				</Grid>
			</Box>

			<Box sx={{ flexGrow: 1, mt: 2 }}>
				<Grid container spacing={2}>
					{CustomerWishlist ? (<>
						<CustomerWishlistTable
							extrasmall={12} small={12} medium={12} color={'green '} text={'Order Table'}
							cardtitle={'Wish Listed Toys'}
							data={CustomerWishlist}
						>

						</CustomerWishlistTable>
					</>) : (<></>)}
					<section className='container pagination-container mt-25'>
						<div className={prevUrl ? "prev button green" : "prev-none"} onClick={handlePrev}>
							Prev
						</div>
						{pages ? (<>
							{pages.map((e, index, url) => {
								return (
									<>
										<div className={e.current ? 'current-page pages' : ' pages'} data-attr={e.url} onClick={() => handlePage(e.url)}>
											{e.page}
										</div>
									</>
								)
							})}
						</>) : (<></>)}
						<div className={nextUrl ? "next button green" : "prev-none"} onClick={handleNext}>
							Next
						</div>
					</section>
					{/* <CounterBox extrasmall={12} small={6} imageUrl={'images/no-data.png'} medium={4} title={'Notifications'} ></CounterBox> */}

				</Grid>
			</Box>

		</>
	)
}

export default CustomerDashboard



