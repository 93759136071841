import axios from 'axios';
import { BASE_API_URL } from '../../server'


const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf

export const FetchShoolApi = async (id) => {
    const apiEndpoint = `${BASE_API_URL}api/order/${id}/`;
    try {
        const response      = await axios.get(apiEndpoint, { withCredentials: true });
        console.log(response)
        const School       = response.data.schools
        return School;

    } catch (error) {
        console.log('***** ERROR in Fetching School *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
    }
};

export const OrderPostApi = async (id,values) => {
    const apiEndpoint = `${BASE_API_URL}api/order/${id}/`;
    // await fetchCsrfToken()
    try {
        const response      = await axios.post(apiEndpoint, 
            values,
        { 
            headers: {
                'Accept': 'application/json',
                
                
            },
            withCredentials: true 
        });
        
        return response.data;

    } catch (error) {
        console.log("this is not working")
    }
};