import axios from 'axios';
import { BASE_API_URL } from '../../server'
import dayjs from 'dayjs';
import { useAppContext } from '../context/UserContext';
import React from 'react'
import Swal from 'sweetalert2';
import { Navigate } from 'react-router-dom';


const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf


const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
        console.error('There was a problem fetching the CSRF token:', error);
    }
};

export const RetriveCartApi = async () => {
    const apiEndpoint = `${BASE_API_URL}api/cart/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const CartList = response.data
        return CartList;

    } catch (error) {
        console.log('***** ERROR in Fetching Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

export const CartUpdateApi = async (cart_item_id, product_id, days, date) => {
    const apiEndpoint = `${BASE_API_URL}api/update-cart/${cart_item_id}/`;
    // await fetchCsrfToken()
    try {
        // Adjust the date by adding 5 hours 30 minutes to match Asia/Kolkata timezone
        const adjustedDate = dayjs(date).add(5, 'hour').add(30, 'minute');

        const response = await axios.put(apiEndpoint,
            {
                item: product_id,
                days: days||7,
                desired_delivery_date: adjustedDate.toISOString().split('T')[0]
            },
            {
                headers: {
                    'Accept': 'application/json',
                },
                withCredentials: true,
            });
        const AddtoCart = response
        return AddtoCart;

    } catch (error) {
        console.log('***** ERROR in Updating in Cart *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

export const CartDeleteApi = async (cart_item_id) => {
    const apiEndpoint = `${BASE_API_URL}api/remove-from-cart/${cart_item_id}/`;
    // await fetchCsrfToken();

    try {
        const response = await axios.delete(apiEndpoint, {
            headers: {
                'Accept': 'application/json',


            },
            withCredentials: true,
        });
        console.log(response)
        return response; // This will return the response from the server

    } catch (error) {
        console.log('***** ERROR in Deleting from Cart *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

export const AddtoCartApi = async (product,days) => {
    const apiEndpoint = `${BASE_API_URL}api/add-to-cart/`;

    const currentDate = new Date();
    const desiredDeliveryDate = new Date(currentDate);
    desiredDeliveryDate.setDate(currentDate.getDate() + 2);
    // await fetchCsrfToken()
    try {

        const response = await axios.post(apiEndpoint,
            {
                item: product.id,
                days: days||7,
                desired_delivery_date: desiredDeliveryDate.toISOString().split('T')[0],
            },
            {
                headers: {
                    'Accept': 'application/json',


                },
                withCredentials: true,
            });
        const AddtoCart = response
        return AddtoCart;

    } catch (error) {
        console.log('***** ERROR in Added in Cart *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

export const TopupApi = async (shortfall) => {
    const apiEndpoint = `${BASE_API_URL}api/topup-tokens/`; // Adjust the endpoint if needed
    // await fetchCsrfToken();
    try {
        const response = await axios.post(apiEndpoint,
            {
                top_up_amount: shortfall
            },
            {
                headers: {
                    'Accept': 'application/json',


                },
                withCredentials: true,
            }
        );
        console.log("THIS IS MY RESPONSE", response)
        return response.data.results;


    } catch (error) {
        if (error.response.data.detail == "Insufficient gg_tokens") {

        } else {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.detail,
                showConfirmButton: false,
                footer: '<a href="/pricing">Buy Subscription</a>'
            }).then(function () {
                window.location = "/";  // This will redirect to the root ("/") path of your application
            });
        }
        // Additional error handling as needed
    }
};

export const RedeemCartApi = async (user_id, updateCartcount, values, station) => {
    const apiEndpoint = `${BASE_API_URL}api/redeem/`; // Adjust the endpoint if needed
    // await fetchCsrfToken();
    try {
        const response = await axios.post(apiEndpoint,
            {
                user: user_id,
                address: `${values.address1} ${values.address2} ${values.city} ${values.pincode}`,
                station: station,
                mobile: values.phoneNumber,
                promo_code: values.promo_code
            },
            {
                headers: {
                    'Accept': 'application/json',
                },
                withCredentials: true,
                timeout: 1000000, 

            },
            { retry: 3, retryDelay: 1000 }
        );
        Swal.fire({
            icon: 'success',
            title: 'Order Confirmed',
            text: 'your order has been successfully placed',
            showConfirmButton: true,
        }).then(function () {
            updateCartcount(0)
            window.location = "/";  // This will redirect to the root ("/") path of your application
        });

        const RedeemCart = response;
        return RedeemCart;

    } catch (error) {
            console.log(error)
        return error
        // Additional error handling as needed
    }
};


