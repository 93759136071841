import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

// Components
import { CounterBox, HotCategories, MostPopular, OrderChart } from '../components/DashboardComponents/DashboardComponents';
import { WishListedToys, UpcomingRenewals } from '../components/Table/TableComponent';
import '../style/components/DashboardComponents/DashboardComponents.css'

// API
// import { ProductDataa } from '../api/ProductApi';
import { DashboardApi } from '../api/DashboardApi';
// import HotCategoriesData from '../../../web/api/HotCategoriesData';




// CounterBOX
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
	...theme.typography.body2,
	padding: "20px",
	boxShadow: "none",
	borderRadius: "10px",
	border: "1px solid #E7E7E7",
	color: "black",
	height: "100%",
}));



function Dashboard() {
	// var product = getMax(ProductDataa, "number_of_days_booked");
	const [Analytics, setAnalytics] = useState(null);
	const [Comingsoon, setCommingSoon] = useState(true)
	useEffect(() => {
		(async () => {
			try {
				const data = await DashboardApi();
				setAnalytics(data); // Make sure you also set the fetched data
			} catch (error) {

			}
		})();
	}, [2]);

	console.log('Analytics', Analytics)

	return (
		<>
			<div className="title-container">
				<h5>Dashboard</h5>
			</div>
			{Analytics ? (
				<>
					{/* Counter Container */}
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<CounterBox extrasmall={12} small={6} medium={4} title={'Active Subscribers'} count={Analytics.active_subscriber_count}></CounterBox>
							<CounterBox
								extrasmall={12}
								small={6}
								medium={4}
								title={'Total Revenue'}
								count={'₹' + (Analytics?.total_revenue - Analytics?.total_deposit?.deposit__sum)}>
							</CounterBox>
							<CounterBox extrasmall={12} small={6} medium={4} title={'Active Schools'} count={Analytics.active_schools}></CounterBox>
						</Grid>
					</Box>
					{/* Counter Container */}

					{/* Order Chart & Most Popular Product */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>

						<Grid container spacing={2}>
							<MostPopular
								cardtitle={'Most Popular Product'}
								extrasmall={12} small={12} medium={12} color={'green '} text={'View Utilisation Report'}
								name={Analytics.popular_product.name}
								product_img={Analytics.popular_product.image}
								days={Analytics.popular_product.total_wishlist}
								revenue={Analytics.popular_product.revenue_generated}
								inventory={Analytics.popular_product.total_stock}

							>
							</MostPopular>

							{/* <Grid item xs={12} sm={6} md={6}>
								<Item className="popular-box">
									<div className="card-title-container">
										<div className="card-title">Order</div>
									</div>
									<div className="chart">
										<OrderChart data={Analytics.order_barchart_data} />
									</div>
								</Item>
							</Grid> */}
						</Grid>
					</Box>
					{/* Order Chart & Most Popular Product */}

					{/* Wish Listed Product & Hot Categories */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Grid container spacing={2}>
							{/* <WishListedToys
								extrasmall={12} small={12} medium={8}
								cardtitle={'WishListed Toys'}
								data={Analytics.top_5_wishlist}
							>


							</WishListedToys> */}

							<HotCategories
								extrasmall={12}
								small={12}
								medium={4}
								data={Analytics.popular_product.total_stock}
								comingsoon={Comingsoon}
							/>


						</Grid>

					</Box>
					{/* Wish Listed Product & Hot Categories */}

					{/* Upcoming Renewals Table */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Grid container spacing={2}>

							<UpcomingRenewals
								extrasmall={12} small={12} medium={12}
								cardtitle={'Upcoming Renewals'}
								data={Analytics.upcoming_renewals}
								comingsoon={Comingsoon}
							></UpcomingRenewals>

						</Grid>
					</Box>
					{/* Upcoming Renewals Table */}
				</>
			) : (
				<></>
			)
			}


		</>
	)
}

export default Dashboard



