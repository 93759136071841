import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AddCtmApi,
  AllProductApi,
  CTMApi,
  EditCTMApi,
  EditStatusCTMApi,
} from "../api/CTMApi";
import { GGTokenApi, PickupEditApi, PickuphApi } from "../api/OrderApi";
import Swal from "sweetalert2";
import { AllCustomerApi, CustomerApi, EditCustomerApi } from "../api/CustomerApi";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useFormik } from "formik";
import * as yup from "yup";
import { stations } from "../../../web/pages/Cart";
import { ProductAPI } from "../../../globalAPIs/ProductApi";
import { ProductApi } from "../api/ProductApi";

const minStartDate = dayjs().add(2, "day");
const maxStartDate = dayjs().add(7, "day");
const tomorrow = dayjs().add(2, "day");

function CTMDrawer(props) {
  const [date, setDate] = useState(
    dayjs(props.propsDetail.desired_delivery_date).add(0, "day")
  );
  const [days, setDays] = useState(props.propsDetail.days); // Initial state
  const [newCost, setnewCost] = useState(props.propsDetail.paid_amount);
  const [status, setstatus] = useState(props.propsDetail.status);

  const [Customer, setCustomer] = useState(null);

  const handleChange = async (items, value) => {
    if (value === 1) {
      setnewCost(items.item.price_1);
    }
    if (value === 3) {
      setnewCost(items.item.price_3);
    }
    if (value === 7) {
      setnewCost(items.item.price_7);
    }
    if (value === 14) {
      setnewCost(items.item.price_14);
    }
    setDays(value);
  };

  const handleDateChange = async (selectedDate) => {
    await setDate(selectedDate);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await CustomerApi();
        setCustomer(data.results);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    console.log(date);
    const currentToken = await value.profile.gg_token;
    const activeOrderPrice = await value.paid_amount;
    let newBalance = (await currentToken) + activeOrderPrice;
    // console.log("value", value);
    // console.log("Current Token", currentToken);
    // console.log("Order Price", activeOrderPrice);
    // console.log("New Balance", newBalance);
    if (newBalance >= newCost) {
      newBalance = newBalance - newCost;
      try {
        const CTMdata = await CTMApi(value, value.id, days, date, newCost);
        console.log("CTMDATAAA", CTMdata);

        const orders = props?.Order;

        const index = orders?.findIndex((item) => item.id === value?.id);

        orders[index].desired_delivery_date =
          CTMdata?.data?.data?.desired_delivery_date;
        orders[index].days = CTMdata?.data?.data?.days;
        orders[index].paid_amount = CTMdata?.data?.data?.paid_amount;

        props?.setOrder([...orders]);

        console.log("desireddd", orders[index], "------", orders);

        const Pickup_CTM_ID = await PickuphApi(
          "?customer_toy_mapping=" + value.id
        );
        const PickupEdit = await PickupEditApi(
          Pickup_CTM_ID.data.results[0].id,
          days,
          date
        );
        const Profile = await GGTokenApi(value.profile.id, newBalance);
      } catch {
      } finally {
        Swal.fire({
          icon: "success",
          title: `Order-${value.id} is successfully updated`,
        }).then(() => {
          props?.setIsDrawerOpen(false);
          props?.setSelectedOrders([]);
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `It looks like ${value.profile.name} dosen't have enough GG Tokens`,
      });
    }
  };

  const handleSubmitStatus = async (e, value) => {
    e.preventDefault();

    try {
      const CTMdata = await EditStatusCTMApi(status, value.id);
      console.log("CTMDATAAA", CTMdata);

      const orders = props?.Order;
      const index = orders?.findIndex((item) => item.id === value?.id);

      orders[index].status = CTMdata?.data?.data?.status;
      props?.setOrder([...orders]);
    } catch {
      Swal.fire({
        icon: "error",
        title: `Something went wrong`,
      }).then(() => {
        props?.setIsDrawerOpen(false);
        props?.setSelectedOrders([]);
      });
    } finally {
      Swal.fire({
        icon: "success",
        title: `Status successfully updated`,
      }).then(() => {
        props?.setIsDrawerOpen(false);
        props?.setSelectedOrders([]);
      });
    }
  };

  console.log("selected Orders", props?.propsDetail, status);

  return (
    <>
      <div className="ctm-card">
        <form>
          {props?.propsDetail?.length > 0 ? (
            <div className="card-details">
              <div className="card-title">
                {props?.propsDetail?.map((product) => (
                  <p>Order ID - {product?.id}</p>
                ))}
              </div>
              <div className="customer-details">
                <div className="detail days">
                  <div className="title">Select Status</div>

                  <Select
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                    fullWidth
                    color="green"
                  >
                    {/* <MenuItem value="Placed">Placed</MenuItem> */}
                    <MenuItem value="Confirmed">Confirmed</MenuItem>
                    <MenuItem value="Delivered">Delivered</MenuItem>
                    <MenuItem value="Pickup">Pickup</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                    {/* // <MenuItem value="Cancelled">Cancelled</MenuItem> */}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-title">
                Order ID - {props.propsDetail.id}
              </div>
              <div className="card-details">
                <div className="product-name">
                  <div className="img-container">
                    <img
                      src={
                        "https://api.growinggiraffe.com" +
                        props.propsDetail.item.image
                      }
                    />
                  </div>
                  {props.propsDetail.item.name}
                </div>
                <div className="customer-details">
                  <div className="detail customer-name">
                    <div className="title">Customer Name</div>
                    {props.propsDetail.user.first_name ? (
                      <>
                        {props.propsDetail.user.first_name +
                          " " +
                          props.propsDetail.user.last_name}
                      </>
                    ) : (
                      <>{props.propsDetail.profile.name}</>
                    )}
                  </div>
                  <div className="detail delivery-date">
                    <div className="title">Delivery Date</div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      color={"green"}
                    >
                      <DatePicker
                        defaultValue={date}
                        // minDate={minStartDate}
                        maxDate={maxStartDate}
                        slotProps={{
                          textField: { variant: "outlined", color: "green" },
                        }}
                        inputFormat="YYYY-MM-DD"
                        color="green"
                        fullWidth
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="detail days">
                    <div className="title">Days</div>
                    <Select
                      value={days}
                      fullWidth
                      onChange={(e) =>
                        handleChange(props.propsDetail, e.target.value)
                      }
                      color="green"
                    >
                      <MenuItem value={1}>1 day</MenuItem>
                      <MenuItem value={3}>3 days</MenuItem>
                      <MenuItem value={7}>7 days</MenuItem>
                      <MenuItem value={14}>14 days</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="form-group-container mt-25">
            <div className="form-group">
              <button
                className="button green"
                onClick={(e) => {
                  if (props?.propsDetail?.length > 0) {
                    props.propsDetail.forEach((product) =>
                      handleSubmitStatus(e, product)
                    );
                  } else if (props?.propsDetail) {
                    handleSubmit(e, props.propsDetail);
                  }
                }}
              >
                Update CTM
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

function CTMnewORder(props) {
  const validationSchema = yup.object({
    user: yup.string().required("Customer is required"),
    item: yup.string().required("Product is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    desired_delivery_date: yup.string().required("Delivery date is required"),
    delivery_address: yup.string().required("Delivery address is required"),
    mobile: yup.string().required("Phone number is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .min(1, "Quantity cannot be less than 1"),
    days: yup.string().required("Days are required"),
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [days, setDays] = useState(null); // Initial state

  const addCTM = async (values) => {
    console.log("CTM posteddd");
    let bal = selectedCustomer?.gg_token - values?.paid_amount
    let updated_toupToken 
    let updated_GGToken
    if(bal<0){
        updated_toupToken  =  selectedCustomer?.topup_token  + bal
        updated_GGToken = 0
    }else{
        updated_GGToken = bal
        updated_toupToken = selectedCustomer?.topup_token
    }

    const updatedtoken = {
      "gg_token":updated_GGToken,
      "topup_token":updated_toupToken
    }
    try {
      // Make the API request using axios
      const response = await AddCtmApi(values);
      if (response.data) {
        await EditCTMApi(response.data.pk, "Confirmed");
        await EditCustomerApi(updatedtoken, selectedCustomer?.id)
      }
      // Log the response from the API
      console.log("API Response:", response);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error posting data:", error);
    } finally {
      window.location.reload();
    }
  };

  const formik = useFormik({
    initialValues: {
      user: "",
      profile: "",
      paid_amount: 0,
      deposit: 0,
      item: "",
      email: "",
      desired_delivery_date: "",
      delivery_address: "",
      mobile: "",
      quantity: "1",
      station: "",
      status: "Placed",
      days: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if(values?.paid_amount > selectedCustomer?.gg_token+ selectedCustomer?.topup_token){
        Swal.fire({
          icon: "error",
          title: `Insufficient balance`,
          text: 'User does not have enough GG Token',
          showConfirmButton: false,
          timer: 1500
        })
        return;
      }

      addCTM(values);
    },
  });

  const handleChange = async (items, value) => {
    if (value === 1) {
      formik.setFieldValue("paid_amount", items.one); // Set the selected user value
    }
    if (value === 3) {
      formik.setFieldValue("paid_amount", items.three); // Set the selected user value
    }
    if (value === 7) {
      formik.setFieldValue("paid_amount", items.seven); // Set the selected user value
    }
    if (value === 14) {
      formik.setFieldValue("paid_amount", items.fourteen); // Set the selected user value
    }
    formik.setFieldValue("days", value); // Set the selected user value
    setDays(value);
  };

  console.log("formikkkk_data", formik.values, selectedCustomer);

  return (
    <>
      <div className="ctm-card">
        <div className="card-title mb-25">Add a new Order</div>
        <form style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
          {/* Autocomplete Component */}
          <Autocomplete
  options={props?.customer}
  getOptionLabel={(option) => `${option.name} (${option.mobile_number})`}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Customer"
      variant="outlined"
      color="green"
    />
  )}
  onChange={(event, newValue) => {
    // Handle the selection change here
    console.log("customer_name", newValue);
    setSelectedCustomer(newValue);
    formik.setFieldValue("user", newValue?.user?.id); // Set the selected user value

    formik.setFieldValue("profile", newValue?.id); // Set the selected profile id

    formik.setFieldValue("mobile", newValue?.mobile_number ?? ""); // Set the phone number
    formik.setFieldValue("email", newValue?.user?.email ?? ""); // Set the email address
    formik.setFieldValue(
      "delivery_address",
      `${newValue?.address_1 ?? ""} ${newValue?.address_2 ?? ""}`
    ); // Set the email address
  }}
/>

          <Autocomplete
            options={props?.product}
            getOptionLabel={(option) => option.name +"-" + option.sku}
            getOptionDisabled={(option) => !option.stock_status} // Disable options with stock_status false
            renderInput={(params) => (
              <TextField
                {...params}
                name="item"
                label="Select Product"
                variant="outlined"
                color="green"
              />
            )}

            onChange={(event, newValue) => {
              // Handle the selection change here
              console.log("product_name", newValue);
              setSelectedProduct(newValue);
              formik.setFieldValue("item", newValue?.id); // Set the selected user's name
              formik.setFieldValue("deposit", newValue?.deposit); // Set the selected user's name
            }}
          />

          {/* {(selectedCustomer && selectedCustomer?.api_has_active_subscription) && (
            <TextField
              fullWidth
              label="Deposit"
              InputLabelProps={{ shrink: true }}
              color="green"
              value={selectedProduct?.deposit ?? 0}
            />
          )} */}

          <FormControl
            fullWidth
            color="green"
            error={Boolean(formik.errors.mobile)}
          >
            <TextField
              fullWidth
              name="mobile"
              required
              label="Phone"
              color="green"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              helperText={formik.errors.mobile}
            />
          </FormControl>
          <FormControl
            fullWidth
            color="green"
            error={Boolean(formik.errors.email)}
          >
            <TextField
              fullWidth
              name="email"
              required
              label="Email"
              color="green"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.errors.email}
            />
          </FormControl>
          <FormControl
            fullWidth
            color="green"
            error={Boolean(formik.errors.delivery_address)}
          >
            <TextField
              fullWidth
              name="delivery_address"
              required
              label="Address"
              color="green"
              value={formik.values.delivery_address}
              onChange={formik.handleChange}
              helperText={formik.errors.delivery_address}
            />
          </FormControl>

          <Autocomplete
            name="selectedStation"
            value={formik.values.station}
            onChange={(event, newValue) => {
              // Handle the selection change here
              formik.setFieldValue("station", newValue); // Set the selected user value
            }}
            options={stations}
            renderInput={(params) => (
              <TextField
                color="green"
                {...params}
                label="Select Station"
                variant="outlined"
              />
            )}
            isOptionEqualToValue={(option, value) => option === value}
          />

          <FormControl
            fullWidth
            color="green"
            error={Boolean(formik.errors.days)}
          >
            <InputLabel id="days-label">Days</InputLabel>
            <Select
              labelId="days-label"
              id="days"
              name="days"
              value={formik.values.days}
              onChange={(e) => {
                // Update Formik state
                formik.setFieldValue("days", e.target.value);

                // Then call your custom handleChange function
                handleChange(selectedProduct, e.target.value);
              }}
              fullWidth
              disabled={!selectedProduct}
              color="green"
            >
              <MenuItem value={1}>1 day</MenuItem>
              <MenuItem value={3}>3 days</MenuItem>
              <MenuItem value={7}>7 days</MenuItem>
              <MenuItem value={14}>14 days</MenuItem>
            </Select>
            {formik.errors.days ? (
              <FormHelperText>{formik.errors.days}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl fullWidth color="green">
            <TextField
              fullWidth
              name="paid_amount"
              required
              label="Total GG Token"
              color="green"
              value={formik.values.paid_amount}
            />
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs} color="green">
            <DatePicker
            color="green"
            label="Delivery Date"
              name="desired_delivery_date"
              value={formik.values.desired_delivery_date}
              onChange={(newValue) => {
                formik.setFieldValue(
                  "desired_delivery_date",
                  newValue ? newValue.format("YYYY-MM-DD") : ""
                );
              }}
              // minDate={minStartDate} Uncomment if needed
              maxDate={maxStartDate}
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  label="Delivery Date"
                  color="green"
                  variant="outlined"
                />
              )}
            />
          </LocalizationProvider>

          <button
            className="button green"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Add Order
          </button>
        </form>
      </div>
    </>
  );
}
export { CTMDrawer, CTMnewORder };
