import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../../../server'

// Define the API endpoint
const apiEndpoint = `${BASE_API_URL}api/dasboard-analytics/`;

export const DashboardApi = async () => {
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        console.log(response.data)
       
        return response.data;

    } catch (error) {
        throw error;
    }
};

