import React, { useEffect, useState } from 'react'

// CSS
import '../style/pages/checkout/checkout.css';
import { FetchProfileApi } from '../../globalAPIs/ProfileApi';
import { Formik } from 'formik';

import * as Yup from 'yup';

// API
import { SubscriptionAPI } from '../../globalAPIs/SubscriptionApi'
import { FetchShoolApi, OrderPostApi } from '../api/OrderApi';

// Components
import SubscriptionCard from '../components/SubscriptionCard'
import Modal from '@mui/material/Modal';
import RazorpayWidget from '../pages/RazorPayWidget'
import Swal from 'sweetalert2';

// API
import { RegisterApi } from '../api/RegisterApi';
import useLoginApi from '../api/LoginApi';

// Form
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAppContext } from '../context/UserContext';



function Checkout() {
    const { cartCount,updateCartcount, customer, isSuperUser, loggedIn } = useAppContext();
    const [num, setNum] = React.useState();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        pincode: '',
        // school: null,   // added
        div: '',
        standard: '',
        
    });

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        phoneNumber: Yup.string().matches(/^[0-9]{10}$/, 'Phone number should be 10 digits').required('Required'),
        address1: Yup.string().required('Required'),
        address2: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        // school: Yup.number().required('Required'),
        pincode: Yup.string().matches(/^[0-9]{6}$/, 'Pincode should be 6 digits').required('Required'),
        
    });


    const [isLoaded, setIsLoaded] = useState(false);
    const [subscription, setSubscription] = useState([])
    const [school, setSchool] = useState([])
    const { login } = useLoginApi();
    const [razorpay_orderid, setRazorpayOrderId] = useState([])
    const [razorpay_amount, setRazorpayAmount] = useState()
    const [orderid, setOrderId] = useState([])
    const [setted, setValue] = useState(false)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const data = await FetchProfileApi();
                if (loggedIn) {

                    setFormData(prevData => ({
                        ...prevData,  // spread the previous formData to keep other fields
                        name: data.name || '',
                        email: data.user_details.email || '',
                        phoneNumber: data.mobile_number || '',
                        address1: data.address_1 || '',
                        address2: data.address_2 || '',
                        city: data.city || '',
                        pincode: data.postal_code || '',
                        password: data.password || '',
                        confirm_password: data.password || '',
                        is_direct_payment:false,
                    })
                    )
                }
                ;
                setIsLoaded(true);
            } catch (error) {
                setIsLoaded(true);
            }
        };
        const fetchSubscription = async () => {
            try {
                const data = await SubscriptionAPI();
                const response = await data.results
                const urlId = parseInt(window.location.pathname.split("/").pop(), 10);
                const matchedData = response.find(item => item.id === urlId);
                setSubscription(matchedData)
            } catch (error) {

            }
        }

        fetchSubscription();
        fetchProfileData();
    }, []);

    const formSubmit = async (values) => {
        try {
            if (!loggedIn) {
                try {
                    const data = await RegisterApi(values);
                    console.log(data);
                    const user = await login(values); // Here's the change. Use 'await' since login is an async function.
                    const response = await OrderPostApi(subscription.id, values);
                    console.log(response);
                    // setOrderId(response.id);
                    // setRazorpayOrderId(response.razorpay_order_id);
                    // setRazorpayAmount(response.amount_to_pay_razorpay);
                    // setValue(true);
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error,
                        showConfirmButton: true,
                    });
                    return;
                }

            } else {
                const response = await OrderPostApi(subscription.id, values);
                console.log('fiudehfioue',response, values);
                setOrderId(response.id);
                setRazorpayOrderId(response.razorpay_order_id);
                setRazorpayAmount(response.amount_to_pay_razorpay);
                updateCartcount(0)
                setValue(true);
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error is over here',
                showConfirmButton: true,
                confirmButtonText: 'Rent Now'
            }).then(function () {
                window.location = "/toy-store";  // This will redirect to the root ("/") path of your application
            });
        }
    }

    useEffect(() => {
        if (orderid && razorpay_orderid && razorpay_amount && setted) {
            // console.log(orderid,razorpay_orderid,razorpay_amount).
            handleOpen();
        }
    }, [orderid, razorpay_orderid, razorpay_amount, setted]);

    return (
        <>
            <div className='checkout-container container'>

                <div className='left'>
                    <div className="subhead mb-20" >Delivery Information</div>
                    {isLoaded ? (
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log('this is working')
                                formSubmit(values)
                            }}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group-container">
                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="name">Name</InputLabel>
                                            <OutlinedInput
                                                id="name"
                                                name="name"
                                                label="Name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="email">Email Address</InputLabel>
                                            <OutlinedInput
                                                id="email"
                                                name="email"
                                                label="Email Address"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                            />
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            color='green'
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                            disabled={loggedIn?true:false}
                                        />
                                        <TextField
                                            fullWidth
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            color='green'
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            disabled={loggedIn?true:false}
                                        />

                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                                            <OutlinedInput
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                label="Phone Number"
                                                type='number'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneNumber}
                                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth color="green">
                                            <TextField
                                                id="address1"
                                                name="address1"
                                                label="Flat, House no, Building, Company, Apartment"
                                                variant="outlined"
                                                color="green"
                                                multiline
                                                fullWidth
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address1}
                                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth color="green">
                                            <TextField
                                                id="address2"
                                                name="address2"
                                                label="Area, Street, Sector, Village"
                                                variant="outlined"
                                                color="green"
                                                multiline
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address2}
                                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="city">City</InputLabel>
                                            <OutlinedInput
                                                id="city"
                                                name="city"
                                                label="City"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.city}
                                                error={formik.touched.city && Boolean(formik.errors.city)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="pincode">Pincode</InputLabel>
                                            <OutlinedInput
                                                id="pincode"
                                                name="pincode"
                                                label="Pincode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pincode}
                                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                                            />
                                        </FormControl>

                                    </div>
                                    {/* <div className="subhead mb-20" >Child Information</div>
                                    <div className="form-group-container">
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="demo-simple-select-label" color="green">School</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formik.values.school}
                                                onChange={formik.handleChange}
                                                label="School"
                                                color='green'
                                                key="School"
                                                name="school"
                                            >
                                                {school.map((item) => {
                                                    if (item.name) { // Check if schoolName is not null or undefined
                                                        return <MenuItem key={item.pk} value={item.name}>{item.name}</MenuItem>;
                                                    }
                                                    return null; // Return null for null or undefined schoolNames
                                                })}
                                            </Select>

                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="standard">Standard</InputLabel>
                                            <OutlinedInput
                                                id="standard"
                                                name="standard"
                                                label="standard"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.standard}
                                                error={formik.touched.standard && Boolean(formik.errors.standard)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <InputLabel htmlFor="division">Division</InputLabel>
                                            <OutlinedInput
                                                id="division"
                                                name="division"
                                                label="division"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.division || ''}
                                                error={formik.touched.division && Boolean(formik.errors.division)}
                                            />
                                        </FormControl>
                                        

                                    </div> */}

                                    <div className="form-group mt-20">
                                        <input type="submit" className='button green' value='Proceed to Checkout' />
                                    </div>
                                </form>
                            )}
                        </Formik>) : (<></>)}

                </div>
                <div className='right'>
                    <SubscriptionCard
                        icon={subscription.image}
                        name={subscription.name}
                        description={subscription.description}
                        price={subscription.subscrption_fees}
                        total={subscription.price}
                        tokens={subscription.total_gg_token}
                        deposit={subscription.refundable_deposit}
                        id={subscription.id}
                        key={subscription.id}
                        duration={subscription.total_month}
                        registeration={subscription.registration_fees}
                    />
                </div>
            </div>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {setted ? (
                    <>
                        <RazorpayWidget id={orderid} amount={razorpay_amount} rpay_order_id={razorpay_orderid}></RazorpayWidget>
                    </>) : (<></>)}
            </Modal>
        </>
    )
}

export default Checkout