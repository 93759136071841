import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
// Components
import Pagination from "../Pagination/Pagination";
import NoData from "../NoData";
import { Buynow } from "../../../../web/components/Button";

// MUI
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDownloadExcel } from "react-export-table-to-excel";
// Style
import "../../style/components/TableComponents/TableComponents.css";
import { Link, NavLink } from "react-router-dom";

// Api
import { ProductDeleteApi } from "../../api/ProductApi";

import { BASE_API_URL } from "../../../../server";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));

// ADMIN
function WishListedToys(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name"); // default sorting by name
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(false);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...props.data].sort((a, b) => {
    const aValue = order === "asc" ? a[orderBy] : b[orderBy];
    const bValue = order === "asc" ? b[orderBy] : a[orderBy];

    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  });

  const filteredData = sortedData.filter((item) => {
    const itemName = item.name ? item.name.toLowerCase() : ""; // Check if item.name is not null/undefined
    const searchTerm = search ? search.toLowerCase() : ""; // Check if search is not null/undefined
    return itemName.includes(searchTerm);
  });

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          {flag ? (
            <>
              <div className="card-title-container">
                <div className="card-title">{props.cardtitle}</div>

                {/* <button
                  className="button yellow"
                  onClick={() => onDownload(filteredData, "TableOneData", true)}
                >
                  Download Excel
                </button> */}
              </div>
              <div className="table-container">
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "40%" }}>
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={order}
                          onClick={() => handleSortRequest("name")}
                        >
                          Product
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        <TableSortLabel
                          active={orderBy === "renvenue_generated"}
                          direction={order}
                          onClick={() =>
                            handleSortRequest("renvenue_generated")
                          }
                        >
                          Revenue
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "total_wishlist"}
                          direction={order}
                          onClick={() => handleSortRequest("total_wishlist")}
                        >
                          Total In WishList
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "30%" }}>
                        <TableSortLabel
                          active={orderBy === "available_stock"}
                          direction={order}
                          onClick={() => handleSortRequest("available_stock")}
                        >
                          Available Stock
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredData.map((item) => (
                      <TableRow key={item.slug}>
                        <TableCell>
                          <div className="flex-container">
                            <div className="img-container">
                              <img src={item.product_img} alt="" />
                            </div>
                            <div className="name">{item.name}</div>
                          </div>
                        </TableCell>
                        <TableCell>{item.renvenue_generated}</TableCell>
                        <TableCell>{item.total_wishlist}</TableCell>
                        <TableCell>{item.available_stock}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          ) : (
            <></>
          )}
        </Item>
      </Grid>
    </>
  );
}

function UpcomingRenewals(props) {
  const data = props.data;
  const tableRef = useRef(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [search, setSearch] = useState("");

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = order === "asc" ? a[orderBy] : b[orderBy];
    const bValue = order === "asc" ? b[orderBy] : a[orderBy];
    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  });

  const filteredData = sortedData.filter((item) => {
    const itemName = item.name ? item.name.toLowerCase() : ""; // Check if item.name is not null/undefined
    const searchTerm = search ? search.toLowerCase() : ""; // Check if search is not null/undefined
    return itemName.includes(searchTerm);
  });

  console.log('upcoming renew', filteredData, data)

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <button className="button yellow" onClick={onDownload}>
              {" "}
              Export excel{" "}
            </button>
          </div>
          <div className="table-container">
            {filteredData == "" ? (
              <>
                <NoData></NoData>
              </>
            ) : (
              <>
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={order}
                          onClick={() => handleSortRequest("name")}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "customerType"}
                          direction={order}
                          onClick={() => handleSortRequest("customerType")}
                        >
                          Customer Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "schoolName"}
                          direction={order}
                          onClick={() => handleSortRequest("schoolName")}
                        >
                          School Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "subscriptionFees"}
                          direction={order}
                          onClick={() => handleSortRequest("subscriptionFees")}
                        >
                          Subscription Fees
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "registrationFees"}
                          direction={order}
                          onClick={() => handleSortRequest("registrationFees")}
                        >
                          Registration Fees
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "discount"}
                          direction={order}
                          onClick={() => handleSortRequest("discount")}
                        >
                          Discount
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "subscriptionFrom"}
                          direction={order}
                          onClick={() => handleSortRequest("subscriptionFrom")}
                        >
                          Subscription From
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "subscriptionTo"}
                          direction={order}
                          onClick={() => handleSortRequest("subscriptionTo")}
                        >
                          Subscription To
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <TableSortLabel
                          active={orderBy === "status"}
                          direction={order}
                          onClick={() => handleSortRequest("status")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>Action</TableCell>

                      {/* ... Add other headers with sorting similarly */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>

                        <TableCell>
                          <div className={item.customer_type + " button"}>
                            {item.customer_type}
                          </div>
                        </TableCell>

                        <TableCell>{item.school_name}</TableCell>

                        <TableCell>{item.subscription.subscrption_fees}</TableCell>

                        <TableCell>{item.subscription.registration_fees}</TableCell>

                        <TableCell>{item.discount}</TableCell>

                        <TableCell>{item.subscription_from}</TableCell>

                        <TableCell>{item.subscription_to}</TableCell>

                        <TableCell>{item.status}</TableCell>

                        <TableCell>
                          <div className="flex-container">
                            <div className="icon-container">
                              <svg
                                width="23"
                                height="24"
                                viewBox="0 0 23 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="21.8"
                                  height="23"
                                  rx="1.5"
                                  fill="#B72126"
                                  fillOpacity="0.13"
                                  stroke="#B72126"
                                />
                                <path
                                  d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                                  stroke="#B72126"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="icon-container">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="23"
                                  height="23"
                                  rx="1.5"
                                  fill="#4B9E99"
                                  fillOpacity="0.12"
                                  stroke="#4B9E99"
                                />
                                <path
                                  d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                  fill="#4B9E99"
                                />
                                <path
                                  d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                  fill="#4B9E99"
                                />
                              </svg>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </Item>
      </Grid>
    </>
  );
}

function OrderTable({
  handleOpen,
  openDrawerWithProduct,
  selectedOrders,
  setSelectedOrders,
  ...props
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [search, setSearch] = useState("");
  const [localData, setLocalData] = useState(props.data); // Initialize localData state

  const handleCheckboxChange = (event, selectedItem) => {
    if (event.target.checked) {
      setSelectedOrders((prevSelected) => [...prevSelected, selectedItem]);
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((item) => item.id !== selectedItem.id)
      );
    }
  };

  const data = props.data;
  console.log(data);

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedItems = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  const handleButtonClick = (product) => {
    // Call the function passed as a prop
    openDrawerWithProduct(product);
  };

  const handleStatusChange = async (item, newStatus) => {
    const response = await axios.put(
      `${BASE_API_URL}api-admin/web/customertoymapping/${item.id}/change/`,
      {
        status: newStatus,
      },
      {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const updatedData = localData.map((dataItem) => {
        if (dataItem.id === item.id) {
          return { ...dataItem, status: newStatus };
        }
        return dataItem;
      });
      setLocalData(updatedData);
      Swal.fire({
        title: "Success!",
        text: "Your action was successful.",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              {/* <NavLink to="order-add" className='button green'>Add Order</NavLink> */}
            </div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                {selectedOrders?.length > 0 && (
                  <TableRow>
                    <TableCell>
                      <button
                        className="button green"
                        onClick={() => handleButtonClick(selectedOrders)}
                      >
                        Edit Status
                      </button>
                    </TableCell>
                    {/* Rest of your table headers */}
                  </TableRow>
                )}
                <TableRow>
                  {[
                    "",
                    "Order ID",
                    "Customer Name",
                    "Toy Name",
                    "Order Date",
                    "Delivery Date",
                    "Pickup Date",
                    "Retained",
                    "Days",
                    "Order Status",
                    "Refundable deposit",
                    "Total",
                    "Action",
                  ].map((headCell, index) => (
                    <TableCell
                      key={"table" + index}
                      sortDirection={
                        orderBy === headCell.toLowerCase().split(" ").join("_")
                          ? order
                          : false
                      }
                    >
                      {headCell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {localData?.map((item) => {
                  // Parse the API date string into a Date object
                  const createdAtDate = new Date(item?.created_at);

                  // Extract year, month, and day from the Date object
                  const year = createdAtDate.getFullYear();
                  const month = String(createdAtDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Add 1 to month because it's zero-based
                  const day = String(createdAtDate.getDate()).padStart(2, "0");

                  // Create the formatted date string "yyyy-mm-dd"
                  const formattedCreatedAt = `${year}-${month}-${day}`;

                  return (
                    <TableRow key={item?.id}>
                      {item?.status == "Closed" ? (
                        <TableCell sx={{ width: "120px" }}>
                          <Checkbox disabled />
                        </TableCell>
                      ) : (
                        <TableCell sx={{ width: "120px" }}>
                          <Checkbox
                            checked={selectedOrders.some(
                              (selectedItem) => selectedItem.id === item.id
                            )}
                            onChange={(e) => handleCheckboxChange(e, item)}
                          />
                        </TableCell>
                      )}
                      <TableCell>{item?.id}</TableCell>
                      {item?.user.first_name ? (
                        <>
                          <TableCell>
                            {item?.user?.first_name +
                              " " +
                              item?.user?.last_name}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{item?.profile?.name}</TableCell>
                        </>
                      )}
                      <TableCell>{item?.item?.name}- {item?.item?.sku}</TableCell>
                      <TableCell>{formattedCreatedAt}</TableCell>

                      {item?.desired_delivery_date === null ? (
                        <>
                          <TableCell>none</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{item?.desired_delivery_date}</TableCell>
                        </>
                      )}
                      {item?.api_get_pickup === null ? (
                        <>
                          <TableCell>none</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            {item?.api_get_pickup[0]?.date_of_pickup}
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        {
                          item?.extended_from && (
                            `Order Retained from ${item?.extended_from?.id}`
                          )
                        }
                      </TableCell>
                      <TableCell>{item?.days}</TableCell>
                      <TableCell>
                        <Select
                          value={item?.status}
                          onChange={(e) =>
                            handleStatusChange(item, e.target.value)
                          }
                          fullWidth
                          color="green"
                        >
                          {/* <MenuItem value="Placed">Placed</MenuItem> */}
                          <MenuItem value="Confirmed">Confirmed</MenuItem>
                          <MenuItem value="Delivered">Delivered</MenuItem>
                          <MenuItem value="Pickup">Pickup</MenuItem>
                          <MenuItem value="Closed">Closed</MenuItem>
                          {/* // <MenuItem value="Cancelled">Cancelled</MenuItem> */}
                        </Select>
                      </TableCell>
                      <TableCell>{item.deposit|| "0"} </TableCell>
                      <TableCell>{item.paid_amount}</TableCell>
                      <TableCell>
                        <div className="flex-container">
                          {item.status !== "Closed" && (
                            <>
                              <div
                                className="icon-container"
                                onClick={() => handleButtonClick(item)}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="1.5"
                                    fill="#4B9E99"
                                    fillOpacity="0.12"
                                    stroke="#4B9E99"
                                  />
                                  <path
                                    d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                    fill="#4B9E99"
                                  />
                                  <path
                                    d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                    fill="#4B9E99"
                                  />
                                </svg>
                              </div>
                              <div
                                className="button green"
                                onClick={() => handleOpen(item)}
                                style={{ float: "right" }}
                              >
                                Retain
                              </div>
                            </>
                          )}
                          {item.status == "Closed" && (
                            <button className="button Disable">
                              Cannot Retain
                            </button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function DispatchSummary(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [search, setSearch] = useState("");
  const [localData, setLocalData] = useState(props.data); // Initialize localData state

  const data = props.data;

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  console.log("localData", localData);
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleStatusChange = async (item, newStatus) => {
    console.log(newStatus);
    const response = await axios.put(
      `${BASE_API_URL}api-admin/web/customertoymapping/${item.customer_toy_mapping.id}/change/`,
      {
        status: newStatus,
      },
      {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const updatedData = localData?.map((dataItem) => {
        if (dataItem.customer_toy_mapping.id === item.customer_toy_mapping.id) {
          return {
            ...dataItem,
            customer_toy_mapping: {
              ...dataItem.customer_toy_mapping,
              status: newStatus,
            },
          };
        }
        return dataItem;
      });
      setLocalData(updatedData);
      Swal.fire({
        title: "Success!",
        text: "Your action was successful.",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex"></div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Order ID",
                    "Customer Name",
                    "Mobile Number",
                    "Toy Name",
                    "Dispatch Date",
                    "Order Status",
                    "Name of Station",
                    "Delivery Address",
                    "Refundable Deposit",
                    "Number of Pieces",
                  ].map((headCell, index) => (
                    <TableCell
                      key={index}
                      sortDirection={
                        orderBy === headCell.toLowerCase().split(" ").join("_")
                          ? order
                          : false
                      }
                    >
                      {headCell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {localData?.map((item) => (
                  <TableRow key={item?.id}>
                    <TableCell>{item?.customer_toy_mapping?.id}</TableCell>
                    {item?.customer_toy_mapping?.user?.first_name ? (
                      <>
                        <TableCell>
                          {item?.customer_toy_mapping.user?.first_name +
                            " " +
                            item?.customer_toy_mapping?.user?.last_name}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          {item?.customer_toy_mapping?.profile?.name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {item?.customer_toy_mapping?.profile?.mobile_number}
                    </TableCell>
                    <TableCell>
                      {item?.product?.name + "-" + item?.product?.article_no}
                    </TableCell>
                    <TableCell>{item.date_of_delivery}</TableCell>
                    <TableCell>
                      <Select
                        value={item?.customer_toy_mapping?.status}
                        onChange={(e) =>
                          handleStatusChange(item, e.target.value)
                        }
                        fullWidth
                        color="green"
                      >
                        {/* <MenuItem value="Placed">Placed</MenuItem> */}
                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                        <MenuItem value="Pickup">Pickup</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        {/* // <MenuItem value="Cancelled">Cancelled</MenuItem> */}
                      </Select>
                    </TableCell>
                    <TableCell>{item.station}</TableCell>
                    <TableCell>
                      {item?.customer_toy_mapping?.delivery_address}
                    </TableCell>
                    <TableCell>{item?.customer_toy_mapping.deposit}</TableCell>
                    <TableCell>{item?.product?.extra?.["No Of Pieces"]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function PickupSummary(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [search, setSearch] = useState("");
  const data = props.data;
  console.log(data);
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleStatusChange = async (item, newStatus) => {
    const response = await axios.put(
      `${BASE_API_URL}api-admin/web/customertoymapping/${item.customer_toy_mapping.id}/change/`,
      {
        status: newStatus,
      },
      {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      Swal.fire({
        title: "Success!",
        text: "Your action was successful.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        window.location.reload();
      });
    }
  };

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex"></div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Order ID",
                    "Customer Name",
                    "Mobile Number",
                    "Toy Name",
                    "Pickup Date",
                    "Order Status",
                    "Name of Station",
                    "Pickup Address",
                    "Refundable Deposit",
                    "Number of Pieces",
                  ].map((headCell, index) => (
                    <TableCell
                      key={index}
                      sortDirection={
                        orderBy === headCell.toLowerCase().split(" ").join("_")
                          ? order
                          : false
                      }
                    >
                      {headCell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item?.id}>
                    <TableCell>{item?.customer_toy_mapping.id}</TableCell>
                    {item?.customer_toy_mapping.user.first_name ? (
                      <>
                        <TableCell>
                          {item?.customer_toy_mapping.user.first_name +
                            " " +
                            item?.customer_toy_mapping.user.last_name}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          {item?.customer_toy_mapping.profile?.name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {item?.customer_toy_mapping.profile?.mobile_number}
                    </TableCell>
                    <TableCell>
                      {item?.product?.name + "-" + item?.product?.article_no}
                    </TableCell>
                    <TableCell>{item?.date_of_pickup}</TableCell>
                    <TableCell>
                      <Select
                        value={item.customer_toy_mapping?.status}
                        onChange={(e) =>
                          handleStatusChange(item, e.target.value)
                        }
                        fullWidth
                        color="green"
                      >
                        {/* <MenuItem value="Placed">Placed</MenuItem> */}
                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                        <MenuItem value="Pickup">Pickup</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        {/* <MenuItem value="Cancelled">Cancelled</MenuItem> */}
                      </Select>
                    </TableCell>
                    <TableCell>{item.station}</TableCell>
                    <TableCell>
                      {item.customer_toy_mapping?.delivery_address}
                    </TableCell>
                    <TableCell>{item?.customer_toy_mapping.deposit}</TableCell>

                    <TableCell>{item?.product?.extra?.["No Of Pieces"]}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function SubscriptionTable(props) {
  const data = props.data;

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              <NavLink to="add-subscription" className="button green">
                Add Subcription
              </NavLink>
            </div>
          </div>
          <div className="table-container">
            <table className="img-table">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Srno</th>
                  <th style={{ width: "40%" }}>Plan Name</th>
                  <th style={{ width: "10%" }}>Price</th>
                  <th style={{ width: "20%" }}>Duration</th>
                  <th style={{ width: "20%" }}>Refundable despoist</th>
                  <th style={{ width: "10%", textAlign: "right" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr key={item.key}>
                      <td style={{}}>{item.key}</td>
                      <td style={{}}>
                        <div>{item.plan}</div>
                      </td>
                      <td style={{}}>₹{item.price}</td>
                      <td style={{}}>{item.duration}</td>
                      <td style={{}}>₹{item.deposit}</td>

                      <td>
                        <div className="flex-container">
                          <div className="icon-container">
                            <svg
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="21.8"
                                height="23"
                                rx="1.5"
                                fill="#B72126"
                                fillOpacity="0.13"
                                stroke="#B72126"
                              />
                              <path
                                d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                                stroke="#B72126"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function CusomterTable(props) {
  const data = props.data;

  console.log("customer Table --", data);
  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            {/* <div className='button-flex'>
							<NavLink to="add-customer" className='button green'>Add Customer</NavLink>
						</div> */}
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Customer ID",
                    "Customer Name",
                    "Mobile Number",
                    "GG Tokens",
                    "Subscription Status",
                    "Customer Type",
                    "School Name",
                    "Action",
                  ].map((headCell, index) => (
                    <TableCell key={index}>{headCell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    {item.name ? (
                      <>
                        <TableCell>{item.name}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          {item.user.first_name + " " + item.user.last_name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>{item.mobile_number}</TableCell>
                    <TableCell>
                      <div className="flex-containe">{item.gg_token + item.topup_token}</div>
                    </TableCell>
                    {item.api_has_active_subscription ? (
                      <>
                        <TableCell>
                          <div className="button Enable">Active</div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <div className="button Disable">InActive</div>
                        </TableCell>
                      </>
                    )}
                    {item.school_name ? (
                      <>
                        <TableCell>
                          <div className="button school">Student</div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <div className="button customer">Customer</div>
                        </TableCell>
                      </>
                    )}
                    {item.school_name ? (
                      <>
                        <TableCell>{item.school_name.name}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>-</TableCell>
                      </>
                    )}
                    <TableCell>
                      <div className="flex-container" style={{ justifyContent: 'flex-end' }}>
                        <div className="icon-container">
                          <Link to={`edit-customer/${item?.user?.id}/${item?.id}`}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </Link>
                        </div>
                        <button className="button green" onClick={() => props?.setRetainDrawer(item)}>
                          Renew Subscription
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function SubscriberTable(props) {
  const data = props.data;
  console.log("subscriber table", data);
  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Customer ID",
                    "Customer Name",
                    "Mobile Number",
                    "GG Tokens",
                    "Subscription Status",
                    "Customer Type",
                    "School Name",
                    "Start Date",
                    "End Date",
                    "Action",
                  ].map((headCell, index) => (
                    <TableCell key={index}>{headCell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    {item.api_get_profile.name ? (
                      <>
                        <TableCell>{item.api_get_profile.name}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          {item.api_get_profile.user_details.first_name +
                            " " +
                            item.api_get_profile.user_details.last_name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>{item.api_get_profile.mobile_number}</TableCell>
                    <TableCell>
                      <div className="flex-containe">
                        {item.api_get_profile.gg_token}
                      </div>
                    </TableCell>
                    {item.subscription && item.subscription.is_active ? (
                      <>
                        <TableCell>
                          <div className="button Enable">Active</div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <div className="button Disable">Expired</div>
                        </TableCell>
                      </>
                    )}
                    {item.api_get_profile.school_name.name ? (
                      <>
                        <TableCell>
                          <div className="button school">Student</div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <div className="button customer">Customer</div>
                        </TableCell>
                      </>
                    )}
                    {item.api_get_profile.school_name.name ? (
                      <>
                        <TableCell>
                          {item.api_get_profile.school_name.name}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>-</TableCell>
                      </>
                    )}

                    {item.subscription ? (
                      <>
                        <TableCell>{item.start_date}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>-</TableCell>
                      </>
                    )}
                    {item.subscription ? (
                      <>
                        <TableCell>{item.end_date}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>-</TableCell>
                      </>
                    )}
                    <TableCell>
                      <div className="flex-container">
                        <div className="icon-container">
                          <Link to={`edit-customer/${item?.user?.id}/${item?.id}`}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function SchoolTable(props) {
  const data = props.data;
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });

  // State variables for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // You can adjust the number of items per page here

  // Calculate the index of the first and last items to be shown on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              <button className="button yellow" onClick={onDownload}>
                {" "}
                Export excel{" "}
              </button>
              <NavLink to="add-schools" className="button green">
                Add School
              </NavLink>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>School Name</th>
                  <th style={{ width: "10%" }}>Email</th>
                  <th style={{ width: "10%" }}>Mobile</th>
                  <th style={{ width: "10%" }}>Address</th>
                  <th style={{ width: "10%" }}>Principal name</th>
                  <th style={{ width: "10%" }}>Status</th>
                  <th style={{ width: "10%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <div>{item.email}</div>
                      </td>
                      <td>
                        <div>{item.mobile}</div>
                      </td>
                      <td>
                        <div>{item.address}</div>
                      </td>
                      <td>
                        <div>{item.principal_name}</div>
                      </td>

                      <td>
                        <div
                          className={
                            item.status ? "Enable button" : "Disable button"
                          }
                        >
                          {item.status ? <>Active</> : <>Inactive</>}
                        </div>
                      </td>
                      <td>
                        <div className="flex-container">
                          <div className="icon-container">
                            <svg
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="21.8"
                                height="23"
                                rx="1.5"
                                fill="#B72126"
                                fillOpacity="0.13"
                                stroke="#B72126"
                              />
                              <path
                                d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                                stroke="#B72126"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* Pagination controls */}
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </Item>
      </Grid>
    </>
  );
}

function ProductTable(props) {
  const data = props.data;
  console.log(data);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const sortedData = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  async function handledelete(id) {
    try {
      Swal.fire({
        title: "Are you sure you want to Delete this product?",
        confirmButtonText: "Yes",
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const response = ProductDeleteApi(id);
          Swal.fire("Deleted!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Product is not deleted", "", "info");
        }
      });
    } catch { }
  }

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              {/* <button className='button yellow' onClick={onDownload}> Export excel </button> */}
              <NavLink to="add-product" className="button green">
                Add Product
              </NavLink>
            </div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "30%" }}>
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={order}
                      onClick={() => handleSortRequest("name")}
                    >
                      Product Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <TableSortLabel
                      active={orderBy === "stock_status"}
                      direction={order}
                      onClick={() => handleSortRequest("stock_status")}
                    >
                      Stock Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "5%" }}>
                    <TableSortLabel
                      active={orderBy === "order"}
                      direction={order}
                      onClick={() => handleSortRequest("order")}
                    >
                      Total Stock
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "5%" }}>
                    <TableSortLabel
                      active={orderBy === "order"}
                      direction={order}
                      onClick={() => handleSortRequest("order")}
                    >
                      Available Stock
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "5%" }}>SKU</TableCell>
                  <TableCell style={{ width: "5%" }}>
                    <TableSortLabel
                      active={orderBy === "seven"}
                      direction={order}
                      onClick={() => handleSortRequest("seven")}
                    >
                      Points
                    </TableSortLabel>
                  </TableCell>

                  <TableCell style={{ width: "15%" }}>
                    <TableSortLabel
                      active={orderBy === "categories"}
                      direction={order}
                      onClick={() => handleSortRequest("categories")}
                    >
                      Categories
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "5%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((item) => (
                  <TableRow key={item?.id}>
                    <TableCell>
                      <div className="flex-container">
                        <div className="img-container">
                          <img src={item?.product_img} alt="" />
                        </div>
                        <div className="name">{item?.name}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.stock_status ? (
                        <>
                          <div className="button Enable">In Stock</div>
                        </>
                      ) : (
                        <>
                          <div className="button Disable">Out of Stock</div>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{item?.stock}</TableCell>
                    <TableCell>{item?.available_stock}</TableCell>
                    <TableCell>{item?.sku}</TableCell>
                    <TableCell>{item?.seven}</TableCell>
                    <TableCell>
                      <div className="category-list">
                        {item.categories?.label ? (
                          <>{item.categories?.label}</>
                        ) : (
                          <>-</>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex-container">
                        <Link to={`edit-product/${item?.id}`} state={item}>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function ProductCategoryTable(props) {
  const data = props.data;
  const tableRef = useRef(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("label");
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const sortedItems = data.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });
  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              <button className="button yellow" onClick={onDownload}>
                {" "}
                Export excel{" "}
              </button>
              <NavLink
                to="/admin/products/add-product-category"
                className="button green"
              >
                Add Product Category
              </NavLink>
            </div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "50%" }}>Category Image</TableCell>
                  <TableCell
                    style={{ width: "40%" }}
                    sortDirection={orderBy === "label" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "label"}
                      direction={orderBy === "label" ? order : "asc"}
                      onClick={() => handleSort("label")}
                    >
                      Category Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems.map((item) => (
                  <TableRow key={item?.pk}>
                    <TableCell>
                      <div className="flex-container">
                        <div className="img-container">
                          <img src={`${BASE_API_URL}` + item?.image} alt="" />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex-container">{item?.label}</div>
                    </TableCell>
                    <TableCell>
                      <div className="flex-container">
                        <div className="icon-container">
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21.8"
                              height="23"
                              rx="1.5"
                              fill="#B72126"
                              fillOpacity="0.13"
                              stroke="#B72126"
                            />
                            <path
                              d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                              stroke="#B72126"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <a href={"products/edit-product/" + item?.id}>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fillOpacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                fill="#4B9E99"
                              />
                              <path
                                d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                fill="#4B9E99"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

// Customer Dashboard
function CustomerWishlistTable(props) {
  const data = props.data;
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });
  // State variables for pagination

  // Calculate the index of the first and last items to be shown on the current page

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              {/* <button className="button yellow" onClick={onDownload}>
                {" "}
                Export excel{" "}
              </button> */}
            </div>
          </div>
          <div className="table-container">
            <table className="img-table">
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Product Name</th>
                  <th style={{ width: "10%" }}>product cost(1 day)</th>
                  <th style={{ width: "10%" }}>product cost(3 day)</th>
                  <th style={{ width: "10%" }}>product cost(7 day)</th>
                  <th style={{ width: "10%" }}>product cost(14 day)</th>
                  <th style={{ width: "15%" }}>Status</th>
                  <th style={{ width: "20%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr key={item.product.id}>
                      <td style={{}}>
                        <div className="flex-container">
                          <div className="img-container">
                            <img
                              src={`${BASE_API_URL}` + item.product.image}
                              alt=""
                            />
                          </div>
                          <div className="name">{item.product.name}</div>
                        </div>
                      </td>
                      <td>{item.product.price_1} Tokens</td>
                      <td>{item.product.price_3} Tokens</td>
                      <td>{item.product.price_7} Tokens</td>
                      <td>{item.product.price_14} Tokens</td>
                      {item.product.is_available ? (
                        <td className="">
                          <div className="button in-stock">In stock</div>
                        </td>
                      ) : (
                        <td className="">
                          <div className="button out-of-stock">
                            Out of Stock
                          </div>
                        </td>
                      )}

                      <td>
                        <Buynow
                          id={item.product.id}
                          show={item.product.is_available}
                          price={item.product.price_7}
                          product={item.product}
                        ></Buynow>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function CustomerOrderTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [search, setSearch] = useState("");
  const data = props.data;

  console.log('mu orderssss', data);

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Order ID",
                    "Toy Name",
                    "Delivery Date",
                    "Pickup Date",
                    "Days",
                    "Order Status",
                    "Total",
                  ].map((headCell, index) => (
                    <TableCell
                      key={index}
                      sortDirection={
                        orderBy === headCell.toLowerCase().split(" ").join("_")
                          ? order
                          : false
                      }
                    >
                      {headCell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => {
                  if (
                    item?.status === "Pickup" ||
                    item?.status === "Closed" ||
                    item?.status === "Cancelled"
                  ) {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>
                          <Link to={`/toy-detail/${item?.item?.slug}`}>
                            <div className="flex-container">
                              <div className="img-container">
                                <img
                                  src={
                                    "https://api.growinggiraffe.com/" +
                                    item?.item?.image
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="name">{item?.item?.name}</div>
                            </div>
                          </Link>
                        </TableCell>

                        {item?.desired_delivery_date === null ? (
                          <TableCell>none</TableCell>
                        ) : (
                          <TableCell>{item?.desired_delivery_date}</TableCell>
                        )}
                        {item?.end_date === null ? (
                          <TableCell>none</TableCell>
                        ) : (
                          <TableCell>{item?.end_date}</TableCell>
                        )}
                        <TableCell>{item?.days}</TableCell>
                        <TableCell>{item?.status}</TableCell>
                        <TableCell>{item?.paid_amount}</TableCell>
                      </TableRow>
                    );
                  } else {
                    // Return null for items with status other than "confirmed" or "delivered"
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function ActiveCustomerOrderTable({ hasRetainedToday, setHasRetainedToday, handleOpen, ...props }) {
  const data = props.data;
  const todayDate = new Date().toISOString().split("T")[0];
  

  useEffect(() => {
    // Check if the user has retained an item today
    const lastRetainedDate = localStorage.getItem('lastRetainedDate');
    if (lastRetainedDate === todayDate) {
      setHasRetainedToday(true);
    }
  }, [todayDate]);

  console.log('active order', data, hasRetainedToday)

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Order ID",
                    "Action",
                    "Toy Name",
                    "Delivery Date",
                    "Pickup Date",
                    "Days",
                    "Order Status",
                    "Total",

                  ].map((headCell, index) => (
                    <TableCell key={index}>{headCell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => {
                  const pickupDate = new Date(item?.end_date); // Replace with the actual property name
                  pickupDate.setDate(pickupDate.getDate() - 2);
                  // Check if pickupDate is valid and today's date is less than pickupDate - 1 day
                  const shouldRenderRetainButton =
                    pickupDate &&
                    todayDate <
                    new Date(pickupDate).toISOString().split("T")[0] 

                  if (
                    item?.status === "Confirmed" ||
                    item?.status === "Delivered"
                  ) {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>{item?.id}</TableCell>

                        <TableCell>
                          {item?.status === "Delivered" ? (
                            <>
                              {shouldRenderRetainButton && hasRetainedToday && !item?.extended_from ? (
                                <div
                                  className="button green"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                  style={{ float: "left" }}
                                >
                                  Retain
                                </div>
                              ) : (
                                <div
                                  className="button Disable"
                                  style={{ float: "left" }}
                                >
                                  {hasRetainedToday ? 'Cannot Retain Try again tomorrow' : 'Cannot Retain'}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div
                                className="button Disable"
                                style={{ float: "left" }}
                              >
                                Cannot Retain
                              </div>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <a href={"/toy-detail/" + item?.item?.slug} target="_blank">
                            <div className="flex-container">
                              <div className="img-container">
                                <img
                                  src={
                                    "https://api.growinggiraffe.com/" +
                                    item?.item?.image
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="name">
                                {item?.item?.name}</div>
                            </div>
                          </a>

                        </TableCell>

                        {item?.desired_delivery_date === null ? (
                          <TableCell>none</TableCell>
                        ) : (
                          <TableCell>{item?.desired_delivery_date}</TableCell>
                        )}
                        {item?.end_date === null ? (
                          <TableCell>none</TableCell>
                        ) : (
                          <TableCell>{item?.end_date}</TableCell>
                        )}
                        <TableCell>{item?.days}</TableCell>
                        <TableCell>{item?.status}</TableCell>
                        <TableCell>{item?.paid_amount}</TableCell>


                      </TableRow>
                    );
                  } else {
                    // Return null for items with status other than "confirmed" or "delivered"
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

function CustomerPastSubscription(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [search, setSearch] = useState("");
  const data = props.data;
  console.log(data);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedItems = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  const filteredItems = sortedItems.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "Order ID",
                    " Name",
                    "Start Date",
                    "End Date",
                    "Total month",
                    "Status",
                    "Refund",
                    "Refund Amount",
                  ].map((headCell, index) => (
                    <TableCell
                      key={index}
                      sortDirection={
                        orderBy === headCell.toLowerCase().split(" ").join("_")
                          ? order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={
                          orderBy ===
                          headCell.toLowerCase().split(" ").join("_")
                        }
                        direction={
                          orderBy ===
                            headCell.toLowerCase().split(" ").join("_")
                            ? order
                            : "asc"
                        }
                        onClick={() =>
                          handleSort(
                            headCell.toLowerCase().split(" ").join("_")
                          )
                        }
                      >
                        {headCell}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.start_date}</TableCell>
                    <TableCell>{item.end_date}</TableCell>
                    <TableCell>{item.total_month}</TableCell>
                    {item.is_active ? (
                      <TableCell>
                        <div className="Enable button">Active</div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <div className=" button Disable">Expired</div>
                      </TableCell>
                    )}
                    {item.is_deposit_refunded ? (
                      <TableCell>
                        <div className="Enable button">Paid</div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <div className=" button customer">Pending</div>
                      </TableCell>
                    )}
                    <TableCell>₹{item.deposit_refund_amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

// School Table
function SchoolStudentTable(props) {
  const data = props.data;
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: props.cardtitle,
    sheet: "Upcoming Renewals",
  });
  // State variables for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // You can adjust the number of items per page here

  // Calculate the index of the first and last items to be shown on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Grid item xs={props.extrasmall} sm={props.small} md={props.medium}>
        <Item className="popular-box">
          <div className="card-title-container">
            <div className="card-title">{props.cardtitle}</div>
            <div className="button-flex">
              <button className="button yellow" onClick={onDownload}>
                {" "}
                Export excel{" "}
              </button>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Student Name</th>
                  <th style={{ width: "10%" }}>Class</th>
                  <th style={{ width: "10%" }}>Registeration Fees</th>
                  <th style={{ width: "10%" }}>Commission Earned</th>
                  <th style={{ width: "10%" }}>Discount</th>
                  <th style={{ width: "20%" }}>Subscription from</th>
                  <th style={{ width: "20%" }}>Subscription to</th>
                  <th style={{ width: "10%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td style={{}}>
                        <div>{item.name}</div>
                      </td>
                      <td>{item.class}</td>
                      <td>{item.registeration_fees}</td>
                      <td>{item.commission_earned}</td>
                      <td>{item.discount}</td>
                      <td>{item.subscription_from}</td>
                      <td>{item.subscription_to}</td>
                      <td>
                        <div className="flex-container">
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="23"
                                height="23"
                                rx="1.5"
                                fill="#4B9E99"
                                fill-opacity="0.12"
                                stroke="#4B9E99"
                              />
                              <path
                                d="M13.5 12C13.5 12.8285 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8285 10.5 12C10.5 11.1715 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1715 13.5 12Z"
                                stroke="#4B9E99"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.22949 12C7.86663 9.97144 9.76177 8.5 12.0006 8.5C14.2394 8.5 16.1345 9.97146 16.7717 12C16.1345 14.0285 14.2394 15.5 12.0006 15.5C9.76177 15.5 7.86662 14.0285 7.22949 12Z"
                                stroke="#4B9E99"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Item>
      </Grid>
    </>
  );
}

export {
  ActiveCustomerOrderTable,
  SchoolStudentTable,
  SubscriberTable,
  CustomerPastSubscription,
  CustomerOrderTable,
  CustomerWishlistTable,
  ProductCategoryTable,
  ProductTable,
  WishListedToys,
  UpcomingRenewals,
  OrderTable,
  CusomterTable,
  DispatchSummary,
  PickupSummary,
  SubscriptionTable,
  SchoolTable,
};
